import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  HStack,
  Td,
  Tr,
  Text,
  Badge,
  useDisclosure,
  useToast,
  IconButton,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdPending, MdCheckCircle, MdCancel } from 'react-icons/md';
import { observer } from 'mobx-react-lite';
import { FaRegStar, FaStar } from 'react-icons/fa';

import CustomTable from '../../components/table/CustomTable';
import DetailDrawer from '../../components/drawer/DetailDrawer';
import UserDetails from '../../components/drawer/UserDetails';
import SearchBar from '../../components/common/SearchBar';
import { userHeaders } from '../../constants/tableHeaders';
import { UserDto } from '../../constants/types';
import { useStore } from '../../hooks/useStore';
import useDebounce from '../../hooks/useDebounce';
import { parseDate } from '../../utils/date';
import { Colors } from '../../theme/colors';

const UsersScreen = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const {
    userStore: {
      users,
      fetchUsers,
      userSearch,
      setUserSearch,
      setDisplayedUser,
      totalUsers,
      loading,
      deleteUser,
      displayedUser,
      featureUser,
    },
  } = useStore();

  const [currentPage, setCurrentPage] = useState(1);
  const debouncedSearch = useDebounce(userSearch, 500);

  const iconColor = useColorModeValue(
    Colors.light.starIcon,
    Colors.dark.starIcon,
  );

  useEffect(() => {
    setCurrentPage(1);
    const fetchUsersAsync = async () => {
      await fetchUsers({ page: 1, limit: 10 });
    };
    fetchUsersAsync();
  }, [debouncedSearch]);

  const handleRowClick = (row: UserDto) => {
    setDisplayedUser(row);
    onOpen();
  };

  const handleDelete = async () => {
    try {
      await deleteUser(displayedUser.id);
      onClose();
      toast({
        title: 'Success',
        description: 'User deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      await fetchUsers({ page: 1, limit: 10 });
      setCurrentPage(1);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to delete user',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <SearchBar
        search={userSearch}
        setSearch={setUserSearch}
        placeholder={'Search users'}
      />
      <CustomTable
        headers={userHeaders}
        rowsPerPage={10}
        totalRows={totalUsers}
        fetchDataTable={fetchUsers}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
      >
        {users.map((data) => {
          const parsedDate = parseDate(data.createdAt);
          const badgeContent = data.pendingRequest
            ? {
                colorScheme: 'orange',
                icon: MdPending,
                text: 'Pending request',
              }
            : data.sellerEnabled
              ? { colorScheme: 'green', icon: MdCheckCircle, text: 'Accepted' }
              : data.stripeUserId
                ? {
                    colorScheme: 'red',
                    icon: MdCancel,
                    text: 'Disabled seller',
                  }
                : { colorScheme: 'gray', icon: MdCancel, text: 'Not a seller' };

          return (
            <Tr key={data.id} onClick={() => handleRowClick(data)}>
              <Td>
                {data.stripeUserId ? (
                  <IconButton
                    aria-label={'feature-auction'}
                    icon={
                      data.featured ? (
                        <FaStar color={iconColor} />
                      ) : (
                        <FaRegStar />
                      )
                    }
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      featureUser(data.id, !data.featured);
                    }}
                  />
                ) : (
                  <></>
                )}
              </Td>
              <Td>
                <Badge
                  colorScheme={badgeContent.colorScheme}
                  display="flex"
                  alignItems="center"
                  gap={1}
                >
                  <badgeContent.icon />
                  {badgeContent.text}
                </Badge>
              </Td>
              <Td>
                <HStack>
                  <Avatar
                    size="sm"
                    src={data.avatar}
                    name={data.userName || ''}
                  />
                  <Text>{data.userName || '-'}</Text>
                </HStack>
              </Td>
              <Td>{data.email}</Td>
              <Td>{data.amountSpent}</Td>
              <Td>{data.amountSold}</Td>
              <Td>{`${parsedDate.date}\n${parsedDate.time}`}</Td>
              <Td>{data.stripeUserId || '-'}</Td>
              <Td>{data.stripeCustomerId || '-'}</Td>
              <Td>{data.paymentFailedStrikes}</Td>
            </Tr>
          );
        })}
      </CustomTable>
      <DetailDrawer isOpen={isOpen} onClose={onClose}>
        <UserDetails onDelete={handleDelete} />
      </DetailDrawer>
    </Box>
  );
};

export default observer(UsersScreen);
