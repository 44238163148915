import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  AspectRatio,
  Box,
  Button,
  FormControl,
  FormLabel, Heading,
  HStack,
  Input,
  Select,
  Text,
  Textarea, useToast,
  VStack,
} from '@chakra-ui/react';
import { ListingType } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { useStore } from '../../hooks/useStore';

import Section from './Section';
import InfoDisplay from './InfoDisplay';

const ActiveListingDetails = () => {
  const {
    listingStore: { displayedActiveListing, updateListing },
  } = useStore();
  const [listing, setListing] = useState(displayedActiveListing);
  const isAuction = displayedActiveListing?.listingType === ListingType.AUCTION;
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setListing(displayedActiveListing);
  }, [displayedActiveListing]);

  const videoUrl = displayedActiveListing?.video.replace('http://', 'https://');

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const updatedListing = {
        ...displayedActiveListing,
        ...listing,
      };
      await updateListing(updatedListing);
      toast({
        title: 'Success',
        description: 'Listing updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (e) {
      console.error(e);
      toast({
        title: 'Error',
        description: 'Failed to update listing',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  const handleChange = (field: string, value: any) => {
    setListing((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const getFormattedDate = (timestamp: string | undefined) => {
    const date = new Date(parseInt(timestamp || ''));
    return date.toLocaleDateString('en-CA').replace(/\//g, '-');
  };

  const getFormattedTime = (timestamp: string | undefined) => {
    const date = new Date(parseInt(timestamp || ''));
    return date.toLocaleTimeString('en-GB');
  };

  return (
    <div>
      <Box display="flex" gap={5} mb={5}>
        <AspectRatio ratio={1} flex={2}>
          <iframe title="video" src={videoUrl} allowFullScreen/>
        </AspectRatio>
        <Text flex={1}>{displayedActiveListing?.title}</Text>
      </Box>
      <Section
        header="Seller Information"
        content={
          <VStack align="start" spacing={1}>
            <InfoDisplay title="Seller username:" value={displayedActiveListing?.user?.userName || ''}/>
            <InfoDisplay title="Email:" value={displayedActiveListing?.user?.email || ''}/>
          </VStack>
        }
      />
      <Box mb={5}/>
      <Section
        header="Edit Listing"
        content={
          <VStack align="start" spacing={3}>
            <FormControl id="title">
              <FormLabel>Title</FormLabel>
              <Input
                placeholder="Title"
                value={listing.title}
                onChange={(e) => handleChange('title', e.target.value)}
              />
            </FormControl>
            <FormControl id="description">
              <FormLabel>Description</FormLabel>
              <Textarea
                placeholder="Description"
                value={listing.description}
                onChange={(e) => handleChange('description', e.target.value)}
              />
            </FormControl>
            {!isAuction && <FormControl id="inStock">
              <FormLabel>In Stock</FormLabel>
              <Input
                placeholder="In Stock"
                type="number"
                value={listing.inStock}
                onChange={(e) => handleChange('inStock', parseInt(e.target.value))}
              />
            </FormControl>}
            <Heading size={'md'} mt={2}>Packaging details</Heading>
            <FormControl id="massUnit">
              <FormLabel>Mass Unit</FormLabel>
              <Select
                placeholder="Select mass unit"
                value={listing.massUnit}
                onChange={(e) => handleChange('massUnit', e.target.value)}
              >
                <option value="lbs">lbs</option>
                <option value="oz">oz</option>
              </Select>
            </FormControl>
            <FormControl id="weight">
              <FormLabel>Weight</FormLabel>
              <Input
                placeholder="Weight"
                type="number"
                value={listing.weight}
                onChange={(e) => handleChange('weight', parseInt(e.target.value))}
              />
            </FormControl>
            <FormControl id="width">
              <FormLabel>Width</FormLabel>
              <Input
                placeholder="Width"
                type="number"
                value={listing.width}
                onChange={(e) => handleChange('width', parseInt(e.target.value))}
              />
            </FormControl>
            <FormControl id="height">
              <FormLabel>Height</FormLabel>
              <Input
                placeholder="Height"
                type="number"
                value={listing.height}
                onChange={(e) => handleChange('height', parseInt(e.target.value))}
              />
            </FormControl>
            <FormControl id="length">
              <FormLabel>Length</FormLabel>
              <Input
                placeholder="Length"
                type="number"
                value={listing.length}
                onChange={(e) => handleChange('length', parseInt(e.target.value))}
              />
            </FormControl>
            {isAuction && <FormControl id="endTime">
              <FormLabel>End Time</FormLabel>
              <HStack spacing={3}>
                <Input
                  type="date"
                  value={getFormattedDate(listing.endTime)}
                  onChange={(e) =>
                    handleChange('endTime', new Date(`${e.target.value}T${getFormattedTime(listing.endTime)}`).getTime().toString())}
                />
                <Input
                  type="time"
                  value={getFormattedTime(listing.endTime)}
                  onChange={(e) =>
                    handleChange('endTime', new Date(`${getFormattedDate(listing.endTime)}T${e.target.value}`).getTime().toString())}
                />
              </HStack>
            </FormControl>}
            <Button onClick={handleSubmit} colorScheme="blue" isLoading={loading}>
              Save
            </Button>
          </VStack>
        }
      />
    </div>
  );
};

export default observer(ActiveListingDetails);
