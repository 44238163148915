import { User, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { makeAutoObservable } from 'mobx';

import { auth } from '../services/firebase';

export class AuthStore {
  loading: boolean = false;
  user: User | undefined = undefined;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setUser(user: User | undefined) {
    this.user = user;
  }

  async signIn(email: string, password: string) {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const tokenResult = await userCredential.user.getIdTokenResult();

      this.setUser(userCredential.user);
      return { ...userCredential.user, isAdmin: tokenResult.claims.admin};
    } catch (error) {
      console.error('Error signing in: ', error);
      throw error;
    }
  }

  async logout() {
    try {
      this.setLoading(true);
      await signOut(auth);
      this.setUser(undefined);
      return true;
    } catch (error) {
      console.error('Error signing out: ', error);
      return false;
    } finally {
      this.setLoading(false);
    }
  }
}
