import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  Text,
  useToast, useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { onAuthStateChanged } from 'firebase/auth';

import { useStore } from '../../hooks/useStore';
import { Routes } from '../../router/routes';
import { auth } from '../../services/firebase';
import { Colors } from '../../theme/colors';

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const {
    authStore: { loading, signIn, setUser, logout },
  } = useStore();
  const toast = useToast();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        navigate(Routes.DashboardHome);
      }
    });

    return () => unsubscribe();
  }, [navigate, setUser]);

  const handleLogin = async () => {
    if (!email || !password)
      return toast({
        title: 'Please fill in your credentials',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });

    try {
      const user = await signIn(email, password);
      if (!user.isAdmin) {
        toast({
          title: 'Access Denied',
          description: 'You are not an admin. Access denied.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        await logout()
        return;
      }
      if (user) navigate(Routes.DashboardHome);
    } catch (error) {
      console.log(error);
      toast({
        title: 'Something went wrong, please try again later.',
        status: 'error',
      });
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Box
        p={8}
        maxWidth="400px"
        borderWidth={1}
        borderRadius={8}
        boxShadow="lg"
        bg={useColorModeValue(Colors.light.gray, Colors.dark.gray)}
      >
        <VStack spacing={4} align="flex-start" w="full">
          <VStack spacing={1} align={['center', 'flex-start']} w="full">
            <Heading size="lg">Sign In</Heading>
            <Text>If you already have an account, login here.</Text>
          </VStack>
          <FormControl isRequired>
            <FormLabel>Email address</FormLabel>
            <Input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <Button
            isLoading={loading}
            colorScheme={'green'}
            width="full"
            mt={4}
            onClick={handleLogin}
          >
            Login
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default LoginScreen;
