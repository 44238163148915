import { makeAutoObservable, runInAction } from 'mobx';

import { UserApi } from '../api/UserApi';
import { UserDto, UserQueryDto } from '../constants/types';

export class UserStore {
  api: UserApi;
  loading: boolean = false;
  users: UserDto[] = [];
  userSearch: string = '';
  totalUsers: number = 0;
  displayedUser: UserDto = {
    id: '',
    userName: '',
    email: '',
    avatar: '',
    createdAt: '',
    stripeCustomerId: '',
    stripeUserId: '',
    paymentFailedStrikes: 0,
    amountSpent: 0,
    amountSold: 0,
    sellerEnabled: false,
    pendingRequest: null,
    sellerFee: 0,
    shippingInfo: [],
    featured: false,
  };

  constructor() {
    this.api = new UserApi();
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setUsers(users: UserDto[]) {
    this.users = users;
  }

  setUserSearch(value: string) {
    this.userSearch = value;
  }

  setTotalUsers(total: number) {
    this.totalUsers = total;
  }

  setDisplayedUser(user: UserDto) {
    this.displayedUser = user;
  }

  async fetchUsers(query?: Partial<UserQueryDto>) {
    query = {
      ...(this.userSearch && { search: this.userSearch }),
      ...(query || {}),
    };

    this.setLoading(true);
    try {
      const response = await this.api.getUsers(query);
      this.setTotalUsers(response.count);

      if (query?.page === 1) {
        this.setUsers(response.items);
      } else {
        this.setUsers([...this.users, ...response.items]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.setLoading(false);
    }
  }

  async getUser(id: string) {
    return await this.api.getUser(id);
  }

  async deleteUser(id: string) {
    await this.api.deleteUser(id);
  }

  async updateUserInStore(userId: string) {
    const updatedUser = await this.getUser(userId);
    this.setDisplayedUser(updatedUser);
    const index = this.users.findIndex((u) => u.id === userId);

    if (index !== -1) {
      this.setUsers([
        ...this.users.slice(0, index),
        updatedUser,
        ...this.users.slice(index + 1),
      ]);
    }
  }

  async enableDisableSeller(id: string, enabled: boolean) {
    await this.api.enableDisableSeller(id, enabled);

    await this.updateUserInStore(id);
  }

  async approveSeller() {
    const request = this.displayedUser.pendingRequest || { id: '' };
    await this.api.approveSeller(request.id);
    await this.updateUserInStore(this.displayedUser.id);
  }

  async rejectSeller() {
    const request = this.displayedUser.pendingRequest || { id: '' };
    await this.api.rejectSeller(request.id);
    await this.updateUserInStore(this.displayedUser.id);
  }

  async updateSellerFee(fee: number) {
    await this.api.updateSellerFee(this.displayedUser.id, fee);
    await this.updateUserInStore(this.displayedUser.id);
  }

  async featureUser(id: string, featured: boolean) {
    const { featured: featuredResult } = await this.api.featureUser(
      id,
      featured,
    );

    runInAction(() => {
      const activeListingIndex = this.users.findIndex((user) => user.id === id);
      if (activeListingIndex !== -1) {
        this.users[activeListingIndex].featured = featuredResult;
      }
    });
  }
}
