"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Role = exports.TaskTypes = exports.CommunityUserType = exports.FirebaseErrors = exports.Errors = exports.Events = exports.OrderIssueOptions = exports.LabelSize = exports.NotificationType = exports.StripePaymentStatus = exports.ShippingService = exports.OfferStatus = exports.FirebaseCollection = exports.OrderShippingStatus = exports.OrderBackendStatus = exports.AuctionMessageType = exports.RatingSteps = exports.IdentityStatus = exports.AddressType = exports.ListingStatus = exports.ShippingType = exports.ListingType = exports.IconDefinition = exports.NotificationsTypes = exports.Theme = exports.Language = void 0;
var Language;
(function (Language) {
    Language["English"] = "en";
})(Language || (exports.Language = Language = {}));
var Theme;
(function (Theme) {
    Theme["Light"] = "light";
    Theme["Dark"] = "dark";
})(Theme || (exports.Theme = Theme = {}));
var NotificationsTypes;
(function (NotificationsTypes) {
    NotificationsTypes["AUCTION_WON"] = "auctionWon";
    NotificationsTypes["AUCTION_ENDED"] = "auctionEnded";
    NotificationsTypes["OFFER_ACCEPTED"] = "offerAccepted";
    NotificationsTypes["OFFER_DECLINED"] = "offerDeclined";
    NotificationsTypes["OFFER_RECEIVED"] = "offerReceived";
    NotificationsTypes["AUCTION_CREATED"] = "auctionCreated";
    NotificationsTypes["ORDER_STATUS_UPDATE"] = "orderStatusUpdate";
    NotificationsTypes["YOU_WERE_OUTBID"] = "youWereOutbid";
    NotificationsTypes["NEW_MESSAGE"] = "newMessage";
})(NotificationsTypes || (exports.NotificationsTypes = NotificationsTypes = {}));
var IconDefinition;
(function (IconDefinition) {
    IconDefinition["Clock"] = "clock";
    IconDefinition["Category"] = "category";
    IconDefinition["ArrowRight"] = "arrowRight";
    IconDefinition["ArrowLeft"] = "arrowLeft";
    IconDefinition["Close"] = "close";
    IconDefinition["Star"] = "star";
    IconDefinition["Trash"] = "trash";
    IconDefinition["ShippingCard"] = "shippingCar";
    IconDefinition["AddCard"] = "addCard";
    IconDefinition["LogoWithText"] = "logoWithText";
    IconDefinition["Back"] = "back";
    IconDefinition["Card"] = "card";
    IconDefinition["Edit"] = "edit";
    IconDefinition["TickCircle"] = "tickCircle";
    IconDefinition["ShippingPackageStatus"] = "shippingPackageStatus";
    IconDefinition["Bell"] = "bell";
    IconDefinition["CubeScan"] = "CubeScan";
    IconDefinition["ShippingLabel"] = "shippingLabel";
    IconDefinition["PackingSlip"] = "packingSlip";
    IconDefinition["Messages"] = "messages";
    IconDefinition["MessageQuestion"] = "messageQuestion";
    IconDefinition["Export"] = "export";
    IconDefinition["Upload"] = "upload";
    IconDefinition["DocumentUpload"] = "documentUpload";
    IconDefinition["Rotate"] = "rotate";
    IconDefinition["Cup"] = "cup";
    IconDefinition["Share"] = "share";
    IconDefinition["Settings"] = "settings";
    IconDefinition["Instagram"] = "instagram";
    IconDefinition["TikTok"] = "tiktok";
})(IconDefinition || (exports.IconDefinition = IconDefinition = {}));
var ListingType;
(function (ListingType) {
    ListingType["DEFAULT"] = "default";
    ListingType["AUCTION"] = "auction";
})(ListingType || (exports.ListingType = ListingType = {}));
var ShippingType;
(function (ShippingType) {
    ShippingType["BUYER_PAYS"] = "buyer_pays";
    ShippingType["SELLER_PAYS"] = "seller_pays";
})(ShippingType || (exports.ShippingType = ShippingType = {}));
var ListingStatus;
(function (ListingStatus) {
    ListingStatus["ACTIVE"] = "active";
    ListingStatus["DRAFT"] = "draft";
})(ListingStatus || (exports.ListingStatus = ListingStatus = {}));
var AddressType;
(function (AddressType) {
    AddressType["DEFAULT"] = "DEFAULT";
    AddressType["RETURN_ADDRESS"] = "RETURN_ADDRESS";
})(AddressType || (exports.AddressType = AddressType = {}));
var IdentityStatus;
(function (IdentityStatus) {
    IdentityStatus["Pending"] = "Pending";
    IdentityStatus["Failed"] = "Failed";
    IdentityStatus["Verified"] = "Verified";
})(IdentityStatus || (exports.IdentityStatus = IdentityStatus = {}));
var RatingSteps;
(function (RatingSteps) {
    RatingSteps[RatingSteps["StarRating"] = 1] = "StarRating";
    RatingSteps[RatingSteps["ExperienceRating"] = 2] = "ExperienceRating";
    RatingSteps[RatingSteps["SkipRating"] = 3] = "SkipRating";
})(RatingSteps || (exports.RatingSteps = RatingSteps = {}));
var AuctionMessageType;
(function (AuctionMessageType) {
    AuctionMessageType["Bid"] = "bid";
    AuctionMessageType["Text"] = "text";
})(AuctionMessageType || (exports.AuctionMessageType = AuctionMessageType = {}));
// as it comes from the backend
var OrderBackendStatus;
(function (OrderBackendStatus) {
    OrderBackendStatus["PENDING"] = "Pending";
    OrderBackendStatus["CONFIRMED"] = "Confirmed";
    OrderBackendStatus["SHIPPED"] = "Shipped";
    OrderBackendStatus["PAYMENT_PENDING"] = "PAYMENT_PENDING";
    OrderBackendStatus["PAYMENT_FAILED"] = "PAYMENT_FAILED";
    OrderBackendStatus["PAYMENT_CONFIRMED"] = "PAYMENT_CONFIRMED";
    OrderBackendStatus["SHIPPING_LABEL_PRINTED"] = "SHIPPING_LABEL_PRINTED";
    OrderBackendStatus["SHIPPING_MARKED_SHIPPED"] = "SHIPPING_MARKED_SHIPPED";
    OrderBackendStatus["SHIPPING_DELIVERED"] = "SHIPPING_DELIVERED";
    OrderBackendStatus["INFORMATION_RECEIVED"] = "information_received";
    OrderBackendStatus["DELAYED"] = "delayed";
    OrderBackendStatus["DELIVERY_RESCHEDULED"] = "delivery_rescheduled";
    OrderBackendStatus["DELIVERY_SCHEDULED"] = "delivery_scheduled";
    OrderBackendStatus["OUT_FOR_DELIVERY"] = "out_for_delivery";
    OrderBackendStatus["PACKAGE_ACCEPTED"] = "package_accepted";
    OrderBackendStatus["PACKAGE_ARRIVED"] = "package_arrived";
    OrderBackendStatus["PACKAGE_DEPARTED"] = "package_departed";
    OrderBackendStatus["PACKAGE_FORWARD"] = "package_forward";
    OrderBackendStatus["PACKAGE_PROCESSED"] = "package_processed";
    OrderBackendStatus["PACKAGE_PROCESSING"] = "package_processing";
    OrderBackendStatus["DELIVERED"] = "delivered";
    OrderBackendStatus["RETURN_TO_SENDER"] = "return_to_sender";
    OrderBackendStatus["PACKAGE_DISPOSED"] = "package_disposed";
    OrderBackendStatus["OTHER"] = "other";
    OrderBackendStatus["ADDRESS_ISSUE"] = "address_issue";
    OrderBackendStatus["CONTACT_CARRIER"] = "contact_carrier";
    OrderBackendStatus["DELIVERY_ATTEMPTED"] = "delivery_attempted";
    OrderBackendStatus["LOCATION_INACCESSIBLE"] = "location_inaccessible";
    OrderBackendStatus["NOTICE_LEFT"] = "notice_left";
    OrderBackendStatus["PACKAGE_DAMAGED"] = "package_damaged";
    OrderBackendStatus["PACKAGE_HELD"] = "package_held";
    OrderBackendStatus["PICKUP_AVAILABLE"] = "pickup_available";
    OrderBackendStatus["RESCHEDULE_DELIVERY"] = "reschedule_delivery";
    OrderBackendStatus["PACKAGE_UNCLAIMED"] = "package_unclaimed";
    OrderBackendStatus["PACKAGE_UNDELIVERED"] = "package_undelivered";
    OrderBackendStatus["PACKAGE_LOST"] = "package_lost";
    OrderBackendStatus["CANCELLED"] = "cancelled";
})(OrderBackendStatus || (exports.OrderBackendStatus = OrderBackendStatus = {}));
// as it should be displayed in the UI
var OrderShippingStatus;
(function (OrderShippingStatus) {
    OrderShippingStatus["ACTION_NEEDED"] = "Action needed! Payment failed!";
    OrderShippingStatus["AWAITING_BUYER_PAYMENT"] = "Awaiting buyer payment";
    OrderShippingStatus["LABEL_PRINTED"] = "Label Printed";
    OrderShippingStatus["PREPARING"] = "Preparing";
    OrderShippingStatus["AWAITING_SHIPPING"] = "Awaiting Shipping";
    OrderShippingStatus["MARKED_SHIPPED"] = "Marked Shipped";
    OrderShippingStatus["SHIPPED"] = "Shipped";
    OrderShippingStatus["IN_TRANSIT"] = "In Transit";
    OrderShippingStatus["ISSUE"] = "Issue: Contact USPS";
    OrderShippingStatus["DELIVERED"] = "Delivered";
    OrderShippingStatus["CANCELLED"] = "Cancelled";
    OrderShippingStatus["RETURNED"] = "Returned to sender";
})(OrderShippingStatus || (exports.OrderShippingStatus = OrderShippingStatus = {}));
var FirebaseCollection;
(function (FirebaseCollection) {
    FirebaseCollection["ListingDetails"] = "listing-details";
    FirebaseCollection["AuctionMessages"] = "auction-messages";
})(FirebaseCollection || (exports.FirebaseCollection = FirebaseCollection = {}));
var OfferStatus;
(function (OfferStatus) {
    OfferStatus["Pending"] = "PENDING";
    OfferStatus["Accepted"] = "ACCEPTED";
    OfferStatus["Declined"] = "DECLINED";
    OfferStatus["Expired"] = "EXPIRED";
})(OfferStatus || (exports.OfferStatus = OfferStatus = {}));
var ShippingService;
(function (ShippingService) {
    ShippingService["GroundAdvantage"] = "Ground Advantage";
    ShippingService["PriorityMail"] = "Priority Mail";
})(ShippingService || (exports.ShippingService = ShippingService = {}));
var StripePaymentStatus;
(function (StripePaymentStatus) {
    StripePaymentStatus["Succeeded"] = "succeeded";
    StripePaymentStatus["RequiresPaymentMethod"] = "required_payment_method";
})(StripePaymentStatus || (exports.StripePaymentStatus = StripePaymentStatus = {}));
// TODO: Add more notification types as we implement them
var NotificationType;
(function (NotificationType) {
    NotificationType["Comment"] = "commented";
    NotificationType["AuctionEnded"] = "auctionEnded";
    NotificationType["AuctionEnd"] = "auctionEnd";
    NotificationType["AuctionExpired"] = "auctionExpired";
    NotificationType["AuctionEndingSoon"] = "auctionEndingSoon";
    NotificationType["BidPaymentFailed"] = "bidPaymentFailed";
    NotificationType["OutBid"] = "outBid";
    NotificationType["OfferAccepted"] = "offerAccepted";
    NotificationType["OfferReceived"] = "offerReceived";
    NotificationType["OfferExpired"] = "offerExpired";
    NotificationType["OrderPurchased"] = "orderPurchased";
    NotificationType["OrderPlaced"] = "orderPlaced";
    NotificationType["OrderStatusUpdated"] = "orderStatusUpdated";
})(NotificationType || (exports.NotificationType = NotificationType = {}));
var LabelSize;
(function (LabelSize) {
    LabelSize["SIZE_8_5_X_11"] = "8.5\u201D x 11\u201D";
    LabelSize["SIZE_4_X_6"] = "4\u201D x 6\u201D";
})(LabelSize || (exports.LabelSize = LabelSize = {}));
var OrderIssueOptions;
(function (OrderIssueOptions) {
    OrderIssueOptions["DamagedItem"] = "Damaged Item";
    OrderIssueOptions["LostOrDidntReceive"] = "Lost / Didn\u2019t receive item";
    OrderIssueOptions["DeliveredToWrongAddress"] = "Item delivered to the wrong address";
    OrderIssueOptions["ReceivedDifferentItem"] = "Received different item";
})(OrderIssueOptions || (exports.OrderIssueOptions = OrderIssueOptions = {}));
var Events;
(function (Events) {
    Events["JoinRoom"] = "join-room";
    Events["ListingEvent"] = "listing-event";
    Events["UserEvent"] = "user-event";
})(Events || (exports.Events = Events = {}));
var Errors;
(function (Errors) {
    Errors["PaymentDeletedWithActiveBids"] = "paymentDeletedWithActiveBids";
})(Errors || (exports.Errors = Errors = {}));
var FirebaseErrors;
(function (FirebaseErrors) {
    FirebaseErrors["INVALID_EMAIL"] = "auth/invalid-email";
    FirebaseErrors["USER_NOT_FOUND"] = "auth/user-not-found";
    FirebaseErrors["WRONG_PASSWORD"] = "auth/wrong-password";
    FirebaseErrors["REQUIRES_RECENT_LOGIN"] = "auth/requires-recent-login";
    FirebaseErrors["EMAIL_ALREADY_IN_USE"] = "auth/email-already-in-use";
    FirebaseErrors["INVALID_CREDENTIAL"] = "auth/invalid-credential";
    FirebaseErrors["WEAK_PASSWORD"] = "auth/weak-password";
})(FirebaseErrors || (exports.FirebaseErrors = FirebaseErrors = {}));
var CommunityUserType;
(function (CommunityUserType) {
    CommunityUserType["FOLLOWER"] = "follower";
    CommunityUserType["FOLLOWING"] = "following";
})(CommunityUserType || (exports.CommunityUserType = CommunityUserType = {}));
(function (NotificationType) {
    NotificationType["AUCTION_WON"] = "auctionWon";
    NotificationType["AUCTION_ENDED"] = "auctionEnded";
    NotificationType["OFFER_ACCEPTED"] = "offerAccepted";
    NotificationType["OFFER_DECLINED"] = "offerDeclined";
    NotificationType["OFFER_RECEIVED"] = "offerReceived";
    NotificationType["AUCTION_CREATED"] = "auctionCreated";
    NotificationType["ORDER_STATUS_UPDATE"] = "orderStatusUpdate";
    NotificationType["YOU_WERE_OUTBID"] = "youWereOutbid";
    NotificationType["NEW_MESSAGE"] = "newMessage";
})(NotificationType || (exports.NotificationType = NotificationType = {}));
var TaskTypes;
(function (TaskTypes) {
    TaskTypes["SHIPPED"] = "shipped";
    TaskTypes["TWO_DAYS_REMINDER"] = "two_days_reminder";
    TaskTypes["THREE_DAYS_REMINDER"] = "three_days_reminder";
    TaskTypes["AUCTION_PAYMENT_FAILED"] = "auction_payment_failed";
})(TaskTypes || (exports.TaskTypes = TaskTypes = {}));
var Role;
(function (Role) {
    Role["USER"] = "user";
    Role["SELLER"] = "seller";
    Role["BUYER"] = "buyer";
    Role["ADMIN"] = "admin";
    Role["GUEST"] = "guest";
})(Role || (exports.Role = Role = {}));
