"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_USER = void 0;
exports.DEFAULT_USER = {
    photoURL: '',
    timezone: '',
    avatar: '',
    avgRate: 0,
    bidAmount: 0,
    countryCode: '',
    email: '',
    firebaseId: '',
    firstName: '',
    id: '',
    isEmailVerified: false,
    isPhoneVerified: false,
    lastName: '',
    phoneNumber: '',
    stripeCustomerId: '',
    stripeIdentityStatus: '',
    stripeUserId: '',
    userName: '',
    shippingInfo: [],
    totalRatings: 0,
    profileDescription: '',
};
