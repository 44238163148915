import React, { useState } from 'react';
import { AspectRatio, Box, Button, Text, useToast, VStack } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import { useStore } from '../../hooks/useStore';
import ListingTypePill from '../common/ListingTypePill';
import { parseDate } from '../../utils/date';
import ConfirmationModal from '../modals/ConfirmationModal';

import InfoDisplay from './InfoDisplay';
import Section from './Section';

const ListingDetails = () => {
  const {
    listingStore: { displayedSoldListing, cancelOrder },
  } = useStore();
  const parsedDate = parseDate(displayedSoldListing.createdAt);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toast = useToast();

  // replace http with https to avoid mixed content error
  const videoUrl = displayedSoldListing?.listing?.video.replace('http://', 'https://');

  const handleCancelOrder = async () => {
    try {
      await cancelOrder(displayedSoldListing.id);
      toast({
        title: 'Success',
        description: 'Order cancelled successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }  catch (e: any) {
      console.error(e);
      const message = e.response?.data?.message || e.message;

      toast({
        title: 'Error',
        description: message || 'Failed to cancel order',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsModalOpen(false);
    }
  }

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleClick = () => {
    setIsModalOpen(true);
  };

  return (
    <Box p={4}>
      <Box display="flex" gap={5} mb={5}>
        <AspectRatio ratio={1} flex={2}>
          <iframe title="video" src={videoUrl} allowFullScreen/>
        </AspectRatio>
        <Text flex={1}>
          {displayedSoldListing?.listing?.title}
        </Text>
      </Box>
      <VStack spacing={4} align="start">
        <Section
          header="Shipping Information"
          content={
            <VStack align="start" spacing={1}>
              <InfoDisplay title={'Order Tracking No:'} value={displayedSoldListing?.trackingCode}/>
              <InfoDisplay title={'Order Status:'} value={displayedSoldListing?.status}/>
              <InfoDisplay title={'Shipping Price:'} value={'$' + displayedSoldListing?.shippingPrice}/>
              <InfoDisplay title={'Order Price:'} value={'$' + displayedSoldListing?.price}/>
              <InfoDisplay title={'Date Sold:'} value={parsedDate.date + ' ' + parsedDate.time}/>
            </VStack>
          }
        />
        <Section
          header="Listing Detail"
          content={
            <VStack align="start" spacing={1}>
              <InfoDisplay title={'Listing Description:'} value={displayedSoldListing?.listing?.description}/>
              <InfoDisplay title={'Listing Type:'}
                           value={<ListingTypePill type={displayedSoldListing.listing?.listingType}/>}/>
            </VStack>
          }
        />
        <Section
          header="Buyer Information"
          content={
            <VStack align="start" spacing={1}>
              <InfoDisplay title={'Buyer username:'} value={displayedSoldListing?.user?.userName}/>
              <InfoDisplay title={'E-mail:'} value={displayedSoldListing?.user?.email}/>
            </VStack>
          }
        />
        <Section
          header="Seller Information"
          content={
            <VStack align="start" spacing={1}>
              <InfoDisplay title={'Seller username:'} value={displayedSoldListing?.seller?.userName}/>
              <InfoDisplay title={'E-mail:'} value={displayedSoldListing?.seller?.email}/>
            </VStack>
          }
        />
        <Button
          w={'100%'}
          py="25px"
          color="green.400"
          variant="contained"
          border="2px"
          borderColor="green.400"
          onClick={handleClick}
        >
          Cancel Order
        </Button>
        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          title={'Cancel order?'}
          subtitle={'This action cannot be undone'}
          cancelActionLabel={'Dismiss'}
          confirmActionLabel={'Confirm'}
          onCancel={handleModalClose}
          onConfirm={handleCancelOrder}
        />
      </VStack>
    </Box>
  );
};

export default observer(ListingDetails);
