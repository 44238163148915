import { TableHeader } from './types';

export const userHeaders = [
  { displayName: 'Feature', sortKey: '', sortable: false },
  { displayName: 'Seller status', sortKey: '', sortable: false }, // for displaying user request status and seller status
  { displayName: 'Username', sortKey: 'userName', sortable: true },
  { displayName: 'Email Address', sortKey: 'email', sortable: true },
  { displayName: 'Amount Spent', sortKey: 'amountSpent', sortable: false },
  { displayName: 'Earnings', sortKey: 'earnings', sortable: false },
  { displayName: 'Signed Up Date', sortKey: 'createdAt', sortable: true },
  {
    displayName: 'Stripe Account ID',
    sortKey: 'stripeAccountId',
    sortable: false,
  },
  {
    displayName: 'Stripe Customer ID',
    sortKey: 'stripeCustomerId',
    sortable: false,
  },
  {
    displayName: 'Payment Failed Strikes',
    sortKey: 'paymentFailedStrikes',
    sortable: false,
  },
];

export const activeListingHeaders: TableHeader[] = [
  { displayName: 'Feature', sortKey: '', sortable: false },
  { displayName: 'Item name', sortKey: 'title', sortable: true },
  { displayName: 'Description', sortKey: 'description', sortable: true },
  { displayName: 'Date posted', sortKey: 'createdAt', sortable: true },
  { displayName: 'Seller', sortKey: '', sortable: false },
  { displayName: 'Type', sortKey: 'listingType', sortable: true },
  { displayName: 'Quantity', sortKey: 'inStock', sortable: true },
  { displayName: 'Price', sortKey: 'startingBid', sortable: true },
  { displayName: 'Starting bid', sortKey: 'startingBid', sortable: true },
  { displayName: 'Current bid', sortKey: 'currentBid', sortable: false },
];

export const soldListingHeaders: TableHeader[] = [
  { displayName: 'Item name', sortKey: 'listing.title', sortable: false },
  { displayName: 'Listing type', sortKey: 'listingType', sortable: false },
  { displayName: 'Item price', sortKey: 'price', sortable: true },
  { displayName: 'Shipping price', sortKey: 'shippingPrice', sortable: true },
  { displayName: 'Buyer Detail', sortKey: 'user.userName', sortable: false },
  { displayName: 'Seller Detail', sortKey: 'seller.userName', sortable: false },
  { displayName: 'Date sold', sortKey: 'createdAt', sortable: true },
  { displayName: 'Order Status', sortKey: 'status', sortable: true },
  { displayName: 'Tracking number', sortKey: 'trackingCode', sortable: false },
];
