export const Colors = {
  dark: {
    background: '#1C1C1E',
    gray: '#2C2C2E',
    gray1: '#8E8E93',
    gray3: '#3A3A3C',
    text: '#FFFFFF',
    primary: '#00BA5C',
    secondary: '#E88B21',
    fadedText: '#9E9E9E',
    starIcon: '#FBBC05',
  },
  light: {
    background: '#FFFFFF',
    gray: '#F8F8F8',
    gray1: '#8E8E93',
    gray3: '#E8E8E8',
    text: '#000000',
    primary: '#00BA5C',
    secondary: '#E88B21',
    fadedText: '#01010173',
    starIcon: '#FBBC05',
  }
}
