"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.endpoints = void 0;
exports.endpoints = {
    users: {
        base: '/user',
        byEmail: function (email) { return "/user/email/".concat(email); },
        byFirebaseId: function (id) { return "/user/firebase/".concat(id); },
        byId: function (id) { return "/user/".concat(id); },
        delete: '/user/delete-account',
        resendPhoneCode: '/user/resend-phone-code',
        verifyPhoneOtp: '/user/verify-phone-otp',
        updatePhone: '/user/update-phone',
        profile: '/user/profile',
        profilePicture: '/user/profile-picture',
        shippingInfo: '/user/shipping-info',
        shippingInfoSeller: '/user/shipping/info-seller',
        getShippingInfo: '/user/shipping-info',
        getSellerShippingInfo: '/user/shipping-info-seller',
        getSellerProfile: '/user/seller',
        getMyDetails: '/user/my-details',
        deviceToken: '/user/device-token',
        resetBadgeCount: '/user/reset-badge-count',
        checkUserNameExist: '/user/check-username',
        createGuestUser: '/user/guest',
        deleteGuestUser: function (id) { return "/user/guest/".concat(id); },
        checkStripeAccount: function (id) { return "/user/stirpe/".concat(id); },
    },
    listings: {
        base: '/listing',
        video: '/listing/video',
        seller: '/listing/seller',
        incrementEndTime: function (id) { return "/listing/".concat(id, "/increment-time"); },
        share: function (id) { return "/listing/share/".concat(id); },
        checkEnded: function (id) { return "/listing/".concat(id, "/ended"); },
        activeListingsByUserId: function (id) { return "/listing/active-listings/".concat(id); },
        unsoldListingsByUserId: function (id) { return "/listing/unsold-listings/".concat(id); },
    },
    timezones: {
        base: '/timezone',
    },
    category: {
        base: '/category',
    },
    setting: {
        base: '/setting',
        contactUs: '/setting/contact-us',
    },
    shipping: {
        base: '/shipping',
        validateAddress: '/shipping/validate-address',
        quote: '/shipping/quote',
    },
    auctions: {
        base: '/auctions',
    },
    payments: {
        base: '/payments',
        stock: '/payments/stock',
        verificationSession: '/payments/stripe/verification-session',
        createConnectedAccount: '/payments/stripe/connected',
        getPaymentMethod: '/payments/stripe/payment-method',
        createAccountLink: '/payments/stripe/account-link',
        setupIntent: '/payments/stripe/payment-info',
        addCard: '/payments/card',
        paymentWithOrder: '/payments/with-order',
        retry: '/payments/retry',
    },
    draftListing: {
        base: '/draft-listing',
    },
    order: {
        base: '/order',
        pendingReviews: '/order/pending-reviews',
        skipReviews: '/order/skip-reviews',
        purchased: '/order/purchased',
        purchasedByUserId: function (id) { return "/order/purchased-orders/".concat(id); },
        soldByUserId: function (id) { return "/order/sold-orders/".concat(id); },
    },
    rating: {
        base: '/rate',
    },
    customerSupport: {
        base: '/customer-support',
        orderIssue: '/customer-support/order-issue',
    },
    following: {
        base: '/following',
        isFollowing: '/following/is-following',
        followers: '/following/followers',
    },
    offer: {
        base: '/offer',
        accept: function (id) { return "/offer/".concat(id, "/accept"); },
        decline: function (id) { return "/offer/".concat(id, "/decline"); },
    },
    notifications: {
        base: '/notifications',
        markAllAsRead: '/notifications/mark-all-as-read',
        markAsRead: function (id) { return "/notifications/mark-as-read/".concat(id); },
        countByTypes: '/notifications/count-by-types',
        markAsReadByTypes: '/notifications/mark-as-read-by-types',
        sendNewMessageNotification: '/notifications/send-new-message-notification',
    },
    savedListings: {
        base: '/saved-listing',
    },
    bids: {
        base: '/bid',
        active: '/bid/active',
    },
    userRequests: {
        base: '/user-requests',
    },
};
